import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Avatar",
  "type": "Other",
  "icon": "userLine"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong parentName="p">{`Avatar`}</strong>{` used to show an avatar of the user`}</p>
    <hr></hr>
    <h1>{`Example`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true background=layout",
        "live": "true",
        "center": "true",
        "background": "layout"
      }}>{`() => {
  const email = "drinchev@gmail.com";
  const hash = md5(email.trim());

  return (
    <div style={{ padding: "120px 0" }}>
      <Avatar imageUrl={\`https://www.gravatar.com/avatar/\${hash}\`}>
        Hello World
      </Avatar>
    </div>
  );
};
`}</code></pre>
    <h1>{`Example with Context Menu`}</h1>
    <p><strong parentName="p">{`Avatar`}</strong>{` goes very well with `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/components/context-menu"
        }}>{`Context Menu`}</a></strong>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live=true center=true background=layout",
        "live": "true",
        "center": "true",
        "background": "layout"
      }}>{`() => {
  const email = "drinchev@gmail.com";
  const hash = md5(email.trim());

  return (
    <div style={{ padding: "20px 0 500px 0" }}>
      <Avatar imageUrl={\`https://www.gravatar.com/avatar/\${hash}\`}>
        <ContextMenu>
          <ContextMenuItem
            icon={Icons.userLine}
            title="Profile"
            description="Important account details"
            onClick={() => {}}
          />
          <ContextMenuItem
            icon={Icons.shareSquareLine}
            title="Referrals"
            description="Invite your friends and earn rewards"
            onClick={() => {}}
          />
          <ContextMenuItem
            icon={Icons.lockLine}
            title="2FA security"
            description="Setup 2FA for more security"
            onClick={() => {}}
          />
          <ContextMenuItem
            icon={Icons.cogLine}
            title="Settings"
            description="View additional settings"
            onClick={() => {}}
          />
          <ContextMenuItem
            icon={Icons.brightnessUpLine}
            title="Dark mode"
            description="Switch dark/light mode"
            action={<Toggle />}
          />
          <ContextMenuItem
            onClick={() => {}}
            icon={Icons.shareSquareLine}
            title="Log out"
          />
        </ContextMenu>
      </Avatar>
    </div>
  );
};
`}</code></pre>
    <h1>{`Props`}</h1>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`className`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Additional class name that will be set`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`imageUrl`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The image to show as the avatar`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The content of the dropdown menu`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      